/* global Blob */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.factory:Cart
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .factory('Cart', Cart);

  function Cart(wbResource, OrderItem) {
    var url = 'carts/'
      , CartBase
      , xlsxContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    CartBase = wbResource('cart', url + ':cartId', {cartId: '@id'}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: true
      },
      get: {
        method: 'GET',
        cache: false
      },
      getFile: {
        method: 'GET',
        url: url + ':cartId/files/:fileName',
        params: {
          cartId: '@id',
          fileName: '@fileName'
        },
        headers: {
          accept: xlsxContentType
        },
        responseType: 'arraybuffer',
        cache: false,
        transformResponse: function (data) {
          return {
            response: new Blob([data], {type: xlsxContentType})
          };
        }
      },
      deleteFile: {
        method: 'DELETE',
        url: url + ':cartId/files/:fileName',
        params: {
          cartId: '@id',
          fileName: '@fileName'
        }
      },
      saveInitialData: {
        method: 'POST',
        url: 'saveInitialData'
      },
      updateOrderTypeForSessionUser: {
        method: 'POST',
        url: url + ':cartId/setProductType',
        cache: false,
        params: {
          cartId: '@cartId',
        }
      }
    });

    CartBase.prototype.getOrderItem = function getOrderItem() {
      return OrderItem.getAsSub(url + this.id + '/');
    };

    return CartBase;
  }
}());
